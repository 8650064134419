import { ChangeDetectionStrategy, Component } from '@angular/core'
import { PrincipalTabsComponent } from '@shared/components/principal-tabs/principal-tabs.component'
import { SliderComponent } from '@adds/components/slider/slider.component'
import { KpiListComponent } from '@kpis/components/kpi-list/kpi-list.component'

@Component({
  selector: 'app-home-page',
  standalone: true,
  imports: [PrincipalTabsComponent, SliderComponent, KpiListComponent],
  templateUrl: './home-page.component.html',
  styleUrl: './home-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomePageComponent {}
