import { ChangeDetectionStrategy, Component, signal, inject, computed, CUSTOM_ELEMENTS_SCHEMA, OnInit, ViewChild, ElementRef } from '@angular/core'
import Swiper from 'swiper'
import { SwiperOptions } from 'swiper/types'

import { KpisService } from '@kpis/services/kpis.service'
import { Kpi, KpiType } from '@kpis/models/kpis'
import { ProgressCardComponent } from '@shared/components/progress-card/progress-card.component'

const options: SwiperOptions = {
  slidesPerView: 1,
  breakpoints: {
    640: {
      slidesPerView: 2
    }
  },
  spaceBetween: 0,
  allowTouchMove: false,
  loop: false,
  navigation: true,
  injectStyles: [
    `.swiper-button-next, .swiper-button-prev {
      width: 14px !important;
      color: rgb(255, 255, 255) !important;
      top: 0px;
      bottom: 0px;
      margin: auto;
      background: rgb(10, 36, 64);
      height: 7rem;
      border-radius: 0.5rem;
      padding: 1px 7px;
    }
    
    .swiper-button-next {
      right: 0 !important;
    }

    .swiper-button-next svg {
      right: 2px;
    }
    
    .swiper-button-prev {
      left: 0 !important;
    }

    .swiper-button-prev svg {
      left: 2px;
    }`
  ]
}

@Component({
  selector: 'app-kpi-list',
  standalone: true,
  imports: [ProgressCardComponent],
  templateUrl: './kpi-list.component.html',
  styleUrl: './kpi-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class KpiListComponent implements OnInit {
  kpisService = inject(KpisService)

  kpis = this.kpisService.kpis
  selectedType = signal<string>('hectolitros')

  allTypes = computed(() => {
    const kpis = this.kpis()

    const types = new Map<string, KpiType>()
    kpis.forEach(kpi => {
      kpi.types.forEach(type => {
        types.set(type.type, type)
      })
    })

    return Array.from(types.values())
  })

  @ViewChild('slider', { static: true }) slider!: ElementRef
  swiperInstance!: Swiper

  ngOnInit() {
    this.swiperInstance = Object.assign(this.slider.nativeElement, options)
    this.slider.nativeElement.initialize()
  }

  onTypeChange(event: Event) {
    const selectedType = (event.target as HTMLSelectElement).value
    this.selectedType.set(selectedType)
  }

  getKpiType(kpi: Kpi) {
    const selectedType = this.selectedType()
    return kpi.types.find(item => item.type === selectedType)
  }

  getDetails(kpi: Kpi, type?: KpiType) {
    if (!type) return
    return this.kpisService.getDetails(kpi.id, type)
  }
}
