import { Routes } from '@angular/router'
import { LayoutComponent } from './layout/layout.component'
import { HomePageComponent } from './pages/home-page/home-page.component'
import { isUserAuthenticatedGuard } from '@participant/guards/is-user-authenticated.guard'
import { isUserUnauthenticatedGuard } from '@participant/guards/is-user-unauthenticated.guard'

export const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [isUserAuthenticatedGuard],
    children: [
      {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
      },
      {
        path: 'home',
        component: HomePageComponent,
        data: {
          nav: 'resumen'
        }
      },
      {
        path: 'autoejecucion',
        loadChildren: () => import('./modules/autoejecucion/autoejecucion.routes').then(m => m.routes)
      },
      {
        path: 'adds',
        loadChildren: () => import('./modules/adds/adds.routes').then(m => m.routes)
      },
      {
        path: 'kpis',
        loadChildren: () => import('./modules/kpis/kpis.routes').then(m => m.routes)
      },
      {
        path: 'big5',
        loadChildren: () => import('./modules/big5/big5.routes').then(m => m.routes)
      },
      {
        path: 'bonificaciones',
        loadChildren: () => import('./modules/bonificaciones/bonificaciones.routes').then(m => m.routes)
      },
      {
        path: 'participant',
        loadChildren: () => import('./modules/participant/participant.routes').then(m => m.routes)
      },
      {
        path: 'pos',
        loadChildren: () => import('./modules/pos/pos.routes').then(m => m.routes)
      },
      {
        path: 'radiografia',
        loadChildren: () => import('./modules/radiografia/radiografia.routes').then(m => m.routes)
      },
      {
        path: 'socios_comerciales',
        loadComponent: () => import('./pages/socios-comerciales-page/socios-comerciales-page.component').then(c => c.SociosComercialesPageComponent)
      },
      {
        path: 'modelorama',
        loadComponent: () => import('./pages/modelorama-escuela-page/modelorama-escuela-page.component').then(c => c.ModeloramaEscuelaPageComponent)
      },
      {
        path: 'referral',
        loadComponent: () => import('./pages/referrals-page/referrals-page.component').then(c => c.ReferralsPageComponent)
      },
      {
        path: 'preguntas_frecuentes',
        loadComponent: () => import('./pages/preguntas-frecuentes-page/preguntas-frecuentes-page.component').then(c => c.PreguntasFrecuentesPageComponent)
      }
    ]
  },
  {
    path: '',
    loadChildren: () => import('./auth/auth.routes').then(m => m.routes),
    canActivate: [isUserUnauthenticatedGuard]
  },
  {
    path: 'tos',
    loadComponent: () => import('./pages/terms-page/terms-page.component').then(c => c.TermsPageComponent),
    canActivate: [isUserAuthenticatedGuard]
  }
]
