<div class="rounded-3xl p-6 bg-theme-mediumBlue3/50 border-theme-mediumBlue3 border-2 text-sm">
  <div class="flex justify-between items-center mb-4">
    <p class="font-bold text-base">Mi avance</p>
    <select
      class="py-2 px-4 rounded-3xl text-theme-white shadow-inset-custom-2 bg-theme-darkBlue"
      (change)="onTypeChange($event)"
      id="type">
      <option value="hectolitros">Hectolitros</option>
      <option value="cartones">Cartones</option>
    </select>
  </div>
  <swiper-container
    #slider
    class="relative h-full w-full"
    init="false">
    @for (kpi of kpis(); track kpi.id) {
      @let type = getKpiType(kpi);
      @let details = getDetails(kpi, type);

      @if (type && type.type === selectedType()) {
        <swiper-slide>
          <app-progress-card
            class="flex gap-4 mx-8"
            [name]="kpi.name"
            [goal]="details?.goal || 0"
            [progress]="details?.progress || 0"
            [percentage]="details?.percentage || 0"
            [label]="type.name"
            [colorScheme]="kpi.color"
            [decimals]="type.decimals">
          </app-progress-card>
        </swiper-slide>
      }
    }
  </swiper-container>
</div>
