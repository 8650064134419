<div
  class="absolute z-10 -top-0 h-screen w-full bg-theme-mediumBlue1/70 transition-all ease-in-out duration-300"
  [class]="{ 'left-0': sidebar(), '-left-full': !sidebar() }"
  (click)="hideSidebar($event)">
  <aside class="relative bg-theme-mediumBlue1 w-10/12 md:w-6/12 h-screen p-8">
    <button
      class="absolute -right-2 top-4 w-6 h-6"
      (click)="hideSidebar()">
      <img
        class="w-full"
        src="https://superlikers-themes.s3.amazonaws.com/modelo_para_ganar/v4/icon-blue-ligth-cancel.svg"
        alt="Cerrar Menú lateral" />
    </button>
    <p class="font-bold justify-center flex text-2xl">Bienvenido</p>
    <p class="font-bold justify-center flex text-xl">Julian C.</p>
    <br />
    <ul class="flex flex-col items-start gap-4 mb-8 border-b border-theme-white pb-4">
      @for (item of navList; track item.name) {
        <li class="flex items-center gap-4 transition-color duration-300 hover:text-theme-lightBlue2">
          <img
            class="w-6 h-6"
            [src]="item.image"
            [alt]="item.name" />
          <a [routerLink]="item.redirect">{{ item.name }}</a>
        </li>
      }
    </ul>
    <button
      (click)="logout()"
      class="font-bold m-auto flex">
      CERRAR SESIÓN
    </button>
  </aside>
</div>
