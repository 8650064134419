<header class="top-0 text-sm bg-theme-mediumBlue3 rounded-b-[3rem] flex items-center gap-4 p-4 shadow-inset-custom-1">
  <button
    class="min-w-10"
    (click)="openSidebar()">
    <img
      class="w-full"
      src="https://superlikers-themes.s3.amazonaws.com/modelo_para_ganar/v4/menu.svg"
      alt="Menú" />
  </button>

  <div class="relative grow w-fit sm:static sm:flex sm:items-center sm:flex-wrap sm:gap-x-4">
    <p class="min-w-full w-28 text-nowrap overflow-hidden text-ellipsis font-semibold sm:min-w-fit">JULIAN CLAVIJO</p>
    <p class="min-w-full w-28 text-nowrap overflow-hidden text-ellipsis sm:min-w-fit">
      <span>Cod. Modelo: </span>
      <span class="font-semibold">122222222</span>
    </p>
    <p class="text-xs min-w-full w-[100px] py-1 px-2 rounded-full shadow-inset-custom-2 bg-theme-darkBlue text-nowrap overflow-hidden text-ellipsis sm:min-w-max">
      <span>Modelorama: </span>
      <span class="font-semibold">MODELORAMA EL QUIJOTE</span>
    </p>
  </div>
  <app-notification-button></app-notification-button>
  <img
    routerLink="/"
    class="cursor-pointer"
    width="80"
    src="https://modeloparaganar.s3.us-east-2.amazonaws.com/layout/logo-secundario.png"
    alt="Logo de Modelo para ganar" />
</header>
