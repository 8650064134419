import { ChangeDetectionStrategy, Component, inject } from '@angular/core'
import { RouterLink } from '@angular/router'

import { NotificationButtonComponent } from '@notifications/components/notification-button/notification-button.component'
import { SidebarService } from '../../services/sidebar.service'
import { UserService } from '@participant/services/user.service'

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [NotificationButtonComponent, RouterLink],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {
  sidebarService = inject(SidebarService)
  userService = inject(UserService)

  user = this.userService.user

  openSidebar() {
    this.sidebarService.changeSidebar(true)
  }
}
